.App {
  font-family: sans-serif;
  text-align: center;
}

.outerContainer {
  margin-top: 5em;
  /* or inline-flex */
}

.profile {
  -moz-border-radius: 50px/50px;
  -webkit-border-radius: 50px 50px;
  border-radius: 50px/50px;
  width: 40px;
  height: 40px;
}