.LaunchControlBox {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 4px;
}
.LaunchControlBoxSearch {
  display: inline-block;
  margin-left: auto;
  max-width: calc(max(30%, 200px));
}
